import { render, staticRenderFns } from "./txyujiaCpay1Nomobile.vue?vue&type=template&id=cd4889a2&scoped=true"
import script from "./txyujiaCpay1Nomobile.vue?vue&type=script&lang=js"
export * from "./txyujiaCpay1Nomobile.vue?vue&type=script&lang=js"
import style0 from "./txyujiaCpay1Nomobile.vue?vue&type=style&index=0&id=cd4889a2&prod&scoped=true&lang=less"
import style1 from "./txyujiaCpay1Nomobile.vue?vue&type=style&index=1&id=cd4889a2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd4889a2",
  null
  
)

export default component.exports